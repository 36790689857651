import store from '@/state/store'

export default [
  {
    path: '/',
    name: 'default',
    meta: {
      // authRequired: false,
    },
    component: () => import('./views/dashboards/default'),
  },
  {
    path: '/product-detail/:id',
    name: 'product-detail',
    meta: {
      // authRequired: false,
    },
    component: () => import('./views/dashboards/product-detail'),
  },
  {
    path: '/ar',
    name: 'ar',
    component: () => import('./views/dashboards/ar'),
  },
  {
    path: '/payement',
    name: 'commander',
    component: () => import('./views/dashboards/payement'),
  },
  {
    path: '/payement',
    name: 'acheter',
    component: () => import('./views/dashboards/payement'),
  },
  {
    path: '/panier',
    name: 'panier',
    meta: {
      authRequired: false,
    },
    component: () => import('./views/dashboards/panier'),
  },
  {
    path: '/panier',
    name: 'panier',
    meta: {
      authRequired: false,
    },
    component: () => import('./views/dashboards/panier'),
  },
  {
    path: '/favoris',
    name: 'favoris',
    meta: {
      authRequired: false,
    },
    // component: () => import('./views/dashboards/favoris'),
  },
  {
    path: '/profil',
    name: 'profil',
    meta: {
      authRequired: false,
    },
    component: () => import('./views/dashboards/profil'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    // beforeEnter: (to, from, next) => {
    // },
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        const tokens = store.getters["customauth/tokens"];
        if (tokens && tokens.access_token) {
          next({ name: 'default' })
        }
        // Continue to the login page
        next()
      }
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        const tokens = store.getters["customauth/tokens"];
        if (tokens && tokens.access_token) {
          next({ name: 'default' })
        }
        // Continue to the login page
        next()
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        const tokens = store.getters["customauth/tokens"];
        if (tokens && tokens.access_token) {
          next({ name: 'default' })
        }
        // Continue to the login page
        next()
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('customauth/logout')
        next({ name: "default"})
      },
    },
  },

 
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  {
    path: "*",
    redirect: "404",
  },
]
