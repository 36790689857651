const localSoutenanceSession = JSON.parse(localStorage.getItem('soutenance.session'));

export const state = {
    session_actived: localSoutenanceSession ? localSoutenanceSession : {}
};

export const getters = {
    session_actived: state => state.session_actived,
};

export const mutations = {
    SET_SESSION(state, newValue) {
        state.session_actived = newValue
        console.log("before set local");
        saveState('soutenance.session', newValue)
        console.log("after set local");
    },
};

export const actions = {
    setSession({ commit }, value) {
        commit('SET_SESSION', value)
    },
};

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
