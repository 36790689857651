import { userService } from '../../helpers/authservice/user.service';
import router from '../../router/index'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.user
    },
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        userService.login(email, password)
            .then(
                user => {
                    setUserToLocal(user, commit)
                    // console.log("outside setUserToLocal"); 
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });

                    router.push('/login');

                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({ commit, state }) {
        // if (!state.user) return Promise.resolve(null)
        // const user = getFirebaseBackend().getAuthenticatedUser();
        // commit('SET_CURRENT_USER', user)
        // return user;

        // check if access token is invalid otherwise get a new access from refresh 
        // if refresh token is also invalid we redirect user to login page
        const user = state.user
        commit('SET_CURRENT_USER', user)
        return user;
        
    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('authfack.user', newValue)
    },
};


function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}

async function setUserToLocal(user, commit) {
    // console.log("inside 0 setUserToLocal");
    localStorage.setItem('user', JSON.stringify(user));
    commit('loginSuccess', user);
    // console.log("inside 1 setUserToLocal");
}