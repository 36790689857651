export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 3,
        label: "menuitems.dashboards.list.products",
        link: "/",
        icon: "bx-home-circle",
    },
    {
        id: 4,
        label: "menuitems.dashboards.list.cart",
        link: "/panier",
        icon: "bx bx-cart-alt",
    },
    //{
       // id: 5,
        //label: "menuitems.dashboards.list.favoris",
        //link: "/favoris",
        //icon: "bx-home-circle",
   // },
    {
        id: 6,
        label: "menuitems.dashboards.list.profil",
        link: "/profil",
        icon: "bx bx-user-circle",
    }
];
