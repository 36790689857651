import { userService } from '../../helpers/authservice/user.service';
import router from '../../router/index'

const   ROLE_NONE = 0 ,
        ROLE_ADMIN = 1, 
        ROLE_ETUDIANT = 2, 
        ROLE_ENSEIGNANT = 3, 
        ROLE_ENCADREUR = 4

export const state = {
    status: null,
    tokens: null,
    user: null,
    userInfo: null
}
export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.user
    },
    tokens(state) {
        return state.tokens
    },
    userInfo(state) {
        return state.userInfo
    },
    userAccess(state) {
        return state.user
    },
    isAdmin(state) {
        // return true
        if (state.userInfo) {
            return (state.userInfo.is_active && state.userInfo.is_superuser)
        }else {
            return false
        }
    },
    isEnseignant(state) {
        // return false
        if (state.userInfo) {
            return (state.userInfo.is_active && state.userInfo.role == ROLE_ENSEIGNANT)
        }else {
            return false
        }
    },
    isEtudiant(state) {
        // return false
        if (state.userInfo) {
            return (state.userInfo.is_active && state.userInfo.role == ROLE_ETUDIANT)
        }else {
            return false
        }
    }
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        return userService.login(email, password)
    },
    // Logout the user
    logout({ commit }) {
        console.log("LOGOUT")
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });

                    router.push('/login');

                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({ commit, state }) {
        // if (!state.user) return Promise.resolve(null)
        // const user = getFirebaseBackend().getAuthenticatedUser();
        // commit('SET_CURRENT_USER', user)
        // return user;

        // check if access token is invalid otherwise get a new access from refresh 
        // if refresh token is also invalid we redirect user to login page
        const user = state.user
        commit('SET_CURRENT_USER', user)
        return user;
        
    },
    // Set user infos
    setUserInfo({ commit }, userInfo) {
        commit('SET_USER_INFO', userInfo);
    },
    setUserAccess({ commit }, user) {
        commit('SET_USER_ACCESS', user);
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        const userAccess = getUserAccess(user)
        state.user = userAccess;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        const userAccess = getUserAccess(user)
        state.user = userAccess;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.tokens = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
    },
    SET_TOKENS(state, newValue) {
        state.tokens = newValue
    },
    SET_USER_INFO(state, userInfo) {
        state.userInfo = userInfo
    },
    SET_USER_ACCESS(state, user) {
        const userAccess = getUserAccess(user)
        state.user = userAccess
        saveState('user', userAccess)
    },

};

function getUserAccess(user) {
    return {
        ...user,
        "tokenExpire": new Date().setHours(new Date().getHours() + 5)
        // "tokenExpire": new Date().setMinutes(new Date().getMinutes() + 1)
      }
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}

// async function setUserToLocal(user, commit) {
//     // console.log("inside 0 setUserToLocal");
//     localStorage.setItem('user', JSON.stringify(user));
//     commit('loginSuccess', user);
//     // console.log("inside 1 setUserToLocal");
// }