import { authHeader } from './auth-header';
import no_auth_request from '../utils/no-auth-request'
export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        data: JSON.stringify({ 
                    email, 
                    password 
                })
    };
    return no_auth_request({
        ...requestOptions,
        url: '/login',
    })
}

// function log_user(email, password) {
//     login(email, password)
//         .then( data => {
//             console.log(data)
//             localStorage.setItem('user', JSON.stringify(user));
//         }).catch( error => {
//             console.log(error)
//         })
    
// }


function logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
