import store from '@/state/store'


export function authHeader() {
    // return authorization header with jwt token
    // headers: { 'Content-Type': 'application/json' },
    // console.log("try to get local user");
    // console.log("getters: ", store);
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.access) {
        // console.log("It's OK");
        // alert("GOOD")
        return { 'Authorization': 'Bearer ' + user.access };
    } else {
        // console.log("It's not OK");
        // alert("BAD")
        return {};
    }
}