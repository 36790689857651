
export const state = {
    all_data: null,
    panier: [],
}
export const getters = {
    all_data(state) {
        return state.all_data
    },
    panier(state) {
        return state.panier
    }
}

export const actions = {
    all_data({ commit }, all_data) {
        commit('ALL_DATA', all_data);
    },
    add_to_panier({ commit }, product) {
        commit('ADD_TO_PANIER', product);
    },
    remove_from_panier({ commit }, product) {
        commit('REMOVE_FROM_PANIER', product);
    }
};

export const mutations = {
    ALL_DATA(state, all_data) {
        state.all_data = all_data
    },
    ADD_TO_PANIER(state, product) {
        state.panier.push(product)
    },
    REMOVE_FROM_PANIER(state, product) {
        state.panier = state.panier.filter( element => {
            return element.id != product.id
        })
    },
};
