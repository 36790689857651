import axios from 'axios'
import store from '@/state/store'
import router from '@/router/index'

// console.log("getters: ", store);

const request = axios.create({
    baseURL: process.env.MIX_VUE_APP_BASE_API,
    timeout: 60000*2,
    headers: {
        'Content-Type': 'application/json',
    }
})
const cancelToken = axios.CancelToken
const source  = cancelToken.source()

request.interceptors.request.use( async (config) => {
        // Do something before request is sent
        // if(!config.headers.Authorization){
        //     config.cancelToken = source.token
        //     source.cancel("Requete interrompue: Abscence de token")
        // }
        // console.log("config: ", config);

        return config
    },
     (error) => {
        // Do something with request error

        // ..See Error
            //console.log("error_request", error)
            //alert("error_request: "+ error.message)
            error
        // See Error..
        return Promise.reject(error)
    }
)

request.interceptors.response.use((response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        // ..See datum in console
            const datum = response.data
            //console.log('result_request ', datum )
        //   See datum in console..

        return datum
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        // ..See Error
            //console.log("error_response", error)
            //alert("error_response: " + error.message)
            error
        // See Error..
        // alert("error_response: " + JSON.stringify(error))
        // alert("error_response: " + error.response.status)
        // console.log("in response error");
        // console.log("error_response: ", error.response);

        if (401 === error.response.status) {
            // router.push('/logout');
            store.dispatch('customauth/logout');
            // console.log("try_logout");
        }else {
            return Promise.reject(error)
        }

        return Promise.reject(error)
})

export default request
